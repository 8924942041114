@import 'src/scss/_colors.scss', 'src/scss/_mixins.scss';

.landing {
  @include flex($flex-direction: row, $justify-content: space-between, $align-items: center);
  font-family: 'Satoshi Variable';

  .custom-container {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    @include flex($flex-direction: column, $justify-content: space-between, $align-items: center);
    padding: 60px;

    .hero-img {
      width: 100%;
      height: 100%;
      @include flex($flex-direction: column, $justify-content: center, $align-items: center);
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-radius: 24px;
        object-fit: cover;
      }

      .hero-text {
        position: absolute;
        color: white;
        padding: 10%;
        @include flex(
          $flex-direction: column,
          $justify-content: center,
          $align-items: center,
          $gap: 24px
        );
        align-self: stretch;

        .headline {
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px;
          text-align: left;
          width: 100%;
        }

        .points {
          @include flex($flex-direction: column, $align-items: flex-start, $gap: 16px);
          align-self: stretch;

          .point {
            @include flex($flex-direction: row, $align-items: center, $gap: 8px);
            color: white;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            p span {
              width: 24px;
              height: 24px;
              display: inline-block;
              aspect-ratio: 1/1;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }

    .logo {
      height: 28px;

      img {
        height: 100%;
        object-fit: contain;
      }
    }

    .content {
      @include flex(
        $flex-direction: column,
        $justify-content: center,
        $align-items: center,
        $gap: 24px
      );
      max-width: 320px;
      flex-grow: 1;
      text-align: center;
      .content-text {
        @include flex($flex-direction: column, $align-items: center, $gap: 12px);

        .heading {
          color: $theme-font;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
        }

        .content-subtext {
          color: $theme-icon;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .social-btn {
        a {
          @include flex(
            $flex-direction: row,
            $justify-content: center,
            $align-items: center,
            $gap: 12px
          );
          max-width: 320px;
          padding: 10px 16px;
          border-radius: 8px;
          border: 1px solid $theme-border-gray;
          background: white;
          transition: all 0.2s;

          span {
            color: #364152;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }

          &:hover {
            background-color: lighten($light-gray, 8%);
          }
        }
      }

      .calendar-info {
        color: $theme-purple;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
      }

      .link {
        @include flex($flex-direction: row, $justify-content: center, $align-items: flex-start);
        gap: 4px;
        align-self: stretch;
        color: $theme-icon;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        span {
          color: $theme-purple;
          cursor: pointer;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }
    }

    .footer-content {
      .signup-footer {
        text-align: left;

        a {
          color: $theme-purple;
          font-weight: 700;
        }
      }
    }
  }

  .second {
    @media (max-width: $medium) {
      display: none;
    }
  }
}
