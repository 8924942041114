@import 'src/scss/_colors.scss', 'src/scss/_mixins.scss';

.sharing-options {
  @include flex(
    $flex-direction: column,
    $justify-content: flex-start,
    $gap: 6px,
    $align-items: flex-start
  );

  p {
    font-weight: 600;
    font-size: 13px;
  }

  .custom-sharing-option, .domain-sharing-option {
    padding: 8px 8px 8px 10px;
    border: 1px solid $theme-border-gray;
    font-size: 14px;
    border-radius: 6px;
    height: 40px;

    input {
      width: 80%;
      height: 100%;
      border: none;
      outline: none;
      color: #0d121c;

      &:focus {
        border: none;
      }
    }

    &:focus {
      border: 2px solid $theme-purple;
    }

    span {
      width: 20%;
      padding-left: 5px;
      font-size: 13px;
      color: #757575;
      display: none;
    }

    .show {
      display: inline-block;
    }
  }

  .selected-emails, .selected-domains {
    @include flex(
      $flex-direction: row,
      $justify-content: flex-start,
      $gap: 6px,
      $align-items: flex-start
    );

    flex-wrap: wrap;

    .error {
      color: #D00416;
      font-weight: 500;
      font-size: 12px;
      margin-top: 0;
    }

    .item {
      @include flex(
        $flex-direction: row,
        $justify-content: flex-start,
        $gap: 3px,
        $align-items: flex-start
      );
      border: 1px solid $theme-light-purple-border;
      background-color: $theme-light-purple;
      border-radius: 4px;
      padding: 4px 8px;
      height: 30px;

      span {
        color: #4b5565;
        font-size: 12px;
      }

      .close {
        width: 11px;
        height: 11px;
        margin-left: 2px;
        cursor: pointer;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
