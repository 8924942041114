@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.rating {
  @include flex($flex-direction: row, $align-items: center, $justify-content: flex-start, $gap: 6px);

  .rating-stars {
    @include flex($flex-direction: row, $align-items: center, $gap: 2px);

    span {
      @include flex($flex-direction: row, $align-items: center, $gap: 0);
    }
  }

  .rating-text {
    white-space: nowrap;
    color: #697586;
  }

  .show-cursor {
    cursor: pointer;
  }
}
