@import "src/scss/_colors.scss", "src/scss/_mixins.scss";

.auto-share-toggle {
  @include flex(
          $flex-direction: row,
          $justify-content: flex-start,
          $gap: 8px,
          $align-items: center
  );

  span {
    font-size: 14px;
  }

  $toggle-width: 40px;
  $toggle-height: calc($toggle-width * 0.6);

  .toggle {
    width: $toggle-width;
    height: $toggle-height;
    position: relative;
    border-radius: 25px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    display: flex;
    align-items: center;
    padding: 2px;
    background-color: $theme-disable;

    .toggle-slider {
      background: white;
      border-radius: 50%;
      transition: transform 0.3s ease-in-out;
      width: calc($toggle-height - 7px);
      height: calc($toggle-height - 7px);
      transform: translateX(-1px);
      @include flex;
    }

    input {
      display: none;
    }

    &.active {
      background-color: $theme-purple;

      .toggle-slider {
        background: white;
        transform: translateX(calc($toggle-width - $toggle-height));
      }
    }
  }
}
